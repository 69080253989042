export enum ApiPath {
  Answers = 'answers',
  Auth = 'auth',
  AuthPassword = 'auths/password',
  Backgrounds = 'backgrounds',
  Callback = 'callback',
  CreateUpload = 'create-upload',
  Crossword = 'crossword',
  Embed = 'embed',
  EmbedPreview = 'embed/preview',
  Employees = 'employees',
  Files = 'files',
  FilterCreatedByEmployees = 'filters/created-by-employees',
  FilterProjects = 'filters/projects',
  Login = 'login',
  Logout = 'logout',
  Me = 'me',
  Permissions = 'permissions',
  Projects = 'projects',
  Publish = 'publish',
  Questions = 'questions',
  QuestionsSortOrder = 'questions-sort-order',
  Results = 'results',
  Roles = 'roles',
  Tests = 'tests',
  Themes = 'themes',
  Types = 'types',
  Unpublish = 'unpublish',
}
