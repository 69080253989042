import classNames from 'classnames';
import React, { FC } from 'react';

import { Trash } from '@/assets';

import { message } from '../../atoms';
import { useDeleteBackgroundMutation } from '../../services';
import { ConfirmModal } from '../ConfirmModal';
import styles from './styles.module.scss';

interface Props {
  alt: string;
  id: number;
  large?: boolean;
  onDelete?: () => void;
  testId: number;
  url: string;
}

export const InteractiveImage: FC<Props> = ({ alt, id, large, onDelete, testId, url }) => {
  const [deleteBackground] = useDeleteBackgroundMutation();

  const handleDelete = async (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();

    ConfirmModal({
      cancelText: 'Отменить',
      okText: 'Удалить',
      onOk: async () => {
        await deleteBackground({ id, testId });
        message({
          title: 'Изображение удалено',
          type: 'success',
        });
        // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
        onDelete && onDelete();
      },
      text: 'Вы действительно хотите удалить изображение?',
      title: 'Удалить изображение?',
    });
  };

  return (
    <div className={classNames(styles.container, large && styles.large)}>
      <img src={url} alt={alt} className={styles.image} />
      <div className={styles.deleteIcon} onClick={handleDelete}>
        <Trash className={styles.icon} />
      </div>
    </div>
  );
};
