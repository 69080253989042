import { FileStatus } from '@tests/types';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { inc } from 'ramda';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@/atoms';
import { backgroundsEntitySelector } from '@/selectors';
import { useGetBackgroundsQuery } from '@/services';
import { ButtonType } from '@/types';
import { isDefined } from '@/utils';

import { DrawerWrapper } from '../DrawerWrapper';
import { InfiniteScroll } from '../InfiniteScroll';
import { InteractiveImage } from '../InteractiveImage';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  onSetImage: (id: number) => void;
  testId: number;
}

export const InteractiveImagesModal: FC<Props> = ({ onClose, onSetImage, testId }) => {
  const [page, setPage] = useState(0);

  const { data, isFetching } = useGetBackgroundsQuery(
    { id: testId, isModal: true, offset: page * 30, order: '-id' },
    { refetchOnMountOrArgChange: true, skip: !testId },
  );

  const images = useSelector(backgroundsEntitySelector.selectAll);

  const filteredImages = useMemo(
    () => images.filter((image) => image.status === FileStatus.Complete),
    [images],
  );
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchData = async () => {
    setPage(inc);
  };

  return (
    <DrawerWrapper
      desktopModal
      handleClose={onClose}
      open
      scrollActive
      title="Ранее загруженные"
      modalClassName={styles.modal}
      mobileDimension={768}
      footer={
        <Row gutter={[10, 0]} justify={{ md: 'end', xs: 'center' }}>
          <Col xs={{ flex: '50%' }} md={{ flex: 'none' }}>
            <Row justify="end">
              <Button type={ButtonType.Secondary} onClick={onClose} className={styles.button}>
                Отменить
              </Button>
            </Row>
          </Col>
          <Col xs={{ flex: '50%' }} md={{ flex: 'none' }}>
            <Button
              disabled={!selectedImage}
              type={ButtonType.Primary}
              onClick={() => onSetImage(selectedImage)}
              className={styles.button}
            >
              Добавить
            </Button>
          </Col>
        </Row>
      }
    >
      <div className={styles.body}>
        <InfiniteScroll
          hasMore={isDefined(data) && data?.count > images?.length}
          isFetching={isFetching}
          fetchData={fetchData}
        >
          <div className={styles.grid}>
            {filteredImages.map((image) => (
              <div
                key={image.id}
                className={classNames(styles.image, selectedImage === image.id && styles.selected)}
                onClick={() => {
                  if (selectedImage === image.id) {
                    setSelectedImage(null);
                  } else {
                    setSelectedImage(image.id);
                  }
                }}
              >
                <InteractiveImage
                  large
                  alt={image.name}
                  id={image.id}
                  url={image.url}
                  testId={testId}
                  onDelete={() => setSelectedImage(null)}
                />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </DrawerWrapper>
  );
};
