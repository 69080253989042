import { PermissionResource } from '@tests/types';
import { Dispatch, SetStateAction } from 'react';

import { EntityFilter } from '@/atoms';
import { projectsFilterSelector } from '@/selectors';
import { useGetFilterProjectsQuery } from '@/services';

type FiltersItem = {
  label: string;
  value: number;
};

type FiltersProps = {
  classname?: string;
  onChange?: Dispatch<SetStateAction<FiltersItem[]>>;
  value?: FiltersItem[];
};

export const ProjectsFilter: React.FC<FiltersProps> = ({ classname, onChange, value }) => (
  <EntityFilter
    useEntityListQuery={useGetFilterProjectsQuery}
    entitySelector={projectsFilterSelector}
    permissionResource={PermissionResource.Test} // NOTE: Разрешение для фильтров теста Test:Read
    checkedItems={value}
    setCheckedItems={onChange}
    classname={classname}
  />
);
