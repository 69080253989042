import { isNil } from 'ramda';

export function isDefined<T>(argument: T): argument is Defined<T> & NotNull<T> {
  return argument !== undefined && argument !== null;
}

export const isEmptyObject = (obj: Record<string, any>) => {
  if (obj) {
    return Object.values(obj).every((item) => {
      if (Array.isArray(item)) {
        return item.length === 0;
      }

      return isNil(item);
    });
  }

  return true;
};
