import { ExclamationCircleFilled } from '@ant-design/icons';
import { TestType } from '@tests/types';
import { Col, Form, InputNumber, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, inc, T } from 'ramda';
import React, { useCallback } from 'react';

import { FormBlock } from '@/containers/Blocks/Wrappers';
import { useDebounce, useGetValidationError } from '@/hooks';
import { isQuiz } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  id: number;
  pointsMax: number;
  pointsMin: number;
  previousResultMax: number | undefined;
  resultIndex: number;
  testType: TestType;
  updateResultBlock: any;
};

const getTitleByTemplateType = cond<[TestType], Nullable<string>>([
  [equals(TestType.Test), always('Диапазон баллов')],
  [equals(TestType.Quiz), always('Количество правильных ответов')],
  [T, always(null)],
]);

export const RangeBlock: React.FC<Props> = ({
  id,
  pointsMax,
  pointsMin,
  previousResultMax,
  resultIndex,
  testType,
  updateResultBlock,
}) => {
  const [form] = Form.useForm();
  const { getFieldsValue, submit } = form;

  const pointsMinError = useGetValidationError({
    field: 'pointsMin',
    targetId: id,
  });
  const pointsMaxError = useGetValidationError({
    field: 'pointsMax',
    targetId: id,
  });

  const handleFormChangeHandler = useDebounce(() => {
    submit();
  }, 1000);

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();
    await updateResultBlock({
      ...formValues,
      id,
    });
  }, [getFieldsValue, updateResultBlock, id]);

  const handleValuesChangeHandler = useCallback(() => {
    handleFormChangeHandler.current();
  }, [handleFormChangeHandler]);

  const getMinPoints = useCallback(() => {
    if (resultIndex === 0) {
      return 0;
    }

    if (previousResultMax) {
      return inc(previousResultMax);
    }

    return undefined;
  }, [resultIndex, previousResultMax]);

  const handleValueChange = useCallback(
    (newValue: number | null, formItemName: string) => {
      const validValue = typeof newValue === 'number' ? newValue : 0;
      form.setFieldValue(formItemName, +validValue);
    },
    [form],
  );

  return (
    <FormBlock
      form={form}
      blockTitle={getTitleByTemplateType(testType)}
      initialValues={{
        pointsMax,
        pointsMin: pointsMin || getMinPoints(),
      }}
      updateBlock={handleFinish}
      onValuesChange={handleValuesChangeHandler}
    >
      <Row gutter={[20, 20]} align="middle" className={styles.rangeWrapper}>
        <Col>
          <Row gutter={[20, 20]} align="middle">
            <Col>от</Col>
            <Col>
              <Row
                align="middle"
                gutter={[10, 10]}
                className={classNames(styles.inputWithInfoWrapper, pointsMinError && styles.error)}
              >
                <Form.Item
                  name="pointsMin"
                  style={{ margin: 0 }}
                  validateStatus={pointsMinError && 'error'}
                >
                  <InputNumber
                    bordered={false}
                    min={previousResultMax && inc(previousResultMax)}
                    className={styles.input}
                    max={1000}
                    placeholder={getMinPoints()?.toString()}
                    onChange={(value) => handleValueChange(value, 'pointsMin')}
                  />
                </Form.Item>
                {pointsMinError && (
                  <Tooltip title={pointsMinError?.message}>
                    <ExclamationCircleFilled
                      className={classNames(styles.error, styles.errorIcon)}
                    />
                  </Tooltip>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[20, 20]} align="middle">
            <Col>до</Col>
            <Row
              align="middle"
              gutter={[10, 10]}
              className={classNames(styles.inputWithInfoWrapper, pointsMaxError && styles.error)}
            >
              <Form.Item
                name="pointsMax"
                style={{ margin: 0 }}
                validateStatus={pointsMaxError && 'error'}
              >
                <InputNumber
                  bordered={false}
                  className={classNames(styles.input, isQuiz(testType) && styles.quiz)}
                  max={1000}
                  onChange={(value) => handleValueChange(+value, 'pointsMax')}
                />
              </Form.Item>
              {pointsMaxError && (
                <Tooltip title={pointsMaxError?.message}>
                  <ExclamationCircleFilled className={classNames(styles.error, styles.errorIcon)} />
                </Tooltip>
              )}
            </Row>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
