import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateQuestionBodyPrivate,
  GetManyQuestionsQueryPrivate,
  GetManyQuestionsResponsePrivate,
  QuestionResponsePrivate,
  TestResponsePrivate,
  UpdateQuestionBodyPrivate,
  UpdateQuestionsSortOrderBodyPrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { createUrl } from '@/utils';

import { authGuardQuery } from './queries';
import { testsApi } from './tests';

export const questionsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    createQuestion: build.mutation<QuestionResponsePrivate, CreateQuestionBodyPrivate>({
      invalidatesTags: [{ id: 'List', type: 'Questions' }],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(testsApi.util.invalidateTags(['Tests']));
        });
      },
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Questions),
      }),
    }),
    deleteQuestion: build.mutation<void, number>({
      invalidatesTags: [{ id: 'List', type: 'Questions' }],
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Questions, id),
      }),
    }),
    getQuestionById: build.query<QuestionResponsePrivate<string>, RelationsParams & { id: number }>(
      {
        providesTags: (test) => [{ id: test?.id, type: 'Questions' }],
        query: ({ id, ...params }) => ({
          method: 'GET',
          params: {
            ...params,
          },
          url: createUrl(ApiPath.Questions, id),
        }),
      },
    ),
    getQuestionsList: build.query<
      GetManyQuestionsResponsePrivate,
      RelationsParams & Partial<GetManyQuestionsQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((testQuestion) => ({
          id: testQuestion.id,
          type: 'Questions' as const,
        })) || []),
        { id: 'List', type: 'Questions' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          order: '+sortOrder',
          ...params,
        },
        url: createUrl(ApiPath.Questions),
      }),
    }),
    updateQuestion: build.mutation<
      QuestionResponsePrivate,
      UpdateQuestionBodyPrivate & { id: number }
    >({
      invalidatesTags: (result) => [{ id: result?.id, type: 'Questions' }],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(testsApi.util.invalidateTags(['Tests']));
        });
      },
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Questions, id),
      }),
    }),
    updateQuestionsOrder: build.mutation<
      TestResponsePrivate,
      UpdateQuestionsSortOrderBodyPrivate & { id: number }
    >({
      invalidatesTags: [{ id: 'List', type: 'Questions' }],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(testsApi.util.invalidateTags(['Tests']));
        });
      },
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Tests, id, ApiPath.QuestionsSortOrder),
      }),
    }),
  }),
  reducerPath: 'questionsApi',
  tagTypes: ['Questions', 'Tests'],
});

export const {
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useGetQuestionByIdQuery,
  useGetQuestionsListQuery,
  useUpdateQuestionMutation,
  useUpdateQuestionsOrderMutation,
} = questionsApi;
