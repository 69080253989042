import { LoginBodyPrivate } from '@tests/types';
import { Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { type FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Eye, EyeInvisible, NewLogo } from '@/assets';
import { Button, FormItem } from '@/atoms';
import { useSSOProvider } from '@/hooks';
import { ButtonType } from '@/types';

import { useLoginUserMutation } from '../../services/api/users';
import styles from './styles.module.scss';

export const LoginPage: FC = () => {
  const [showError, setShowError] = useState(false);
  const [loginUser] = useLoginUserMutation();
  const [form] = useForm<LoginBodyPrivate>();
  const navigate = useNavigate();

  const ssoProvider = useSSOProvider();

  const handleSubmit = async (data: LoginBodyPrivate) => {
    const response = await loginUser(data);

    if ('data' in response) {
      navigate('/');
    } else {
      setShowError(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.formWrapper}>
        <div className={styles.logo}>
          <NewLogo />
        </div>
        <Form
          form={form}
          className={styles.form}
          onFinish={handleSubmit}
          onValuesChange={() => setShowError(false)}
        >
          <FormItem
            name="login"
            label="Логин"
            className={styles.formItem}
            flex
            rules={[{ message: '', required: true }]}
          >
            <Input
              className={styles.input}
              status={showError ? 'error' : undefined}
              rootClassName={styles.inputRoot}
              placeholder="Введите логин"
            />
          </FormItem>
          <FormItem
            name="password"
            label="Пароль"
            className={styles.formItem}
            flex
            rules={[{ message: '', required: true }]}
          >
            <Input.Password
              className={styles.input}
              status={showError ? 'error' : undefined}
              rootClassName={styles.inputRoot}
              placeholder="Введите пароль"
              // eslint-disable-next-line react/no-unstable-nested-components
              iconRender={(visible) =>
                visible ? (
                  <Eye className={styles.eyeIcon} />
                ) : (
                  <EyeInvisible className={styles.eyeIcon} />
                )
              }
            />
          </FormItem>
        </Form>
        <div className={styles.buttons}>
          <Button type={ButtonType.Primary} flex onClick={() => form.submit()}>
            Войти
          </Button>

          <Button
            type={ButtonType.Text}
            className={styles.ssoButton}
            flex
            href={ssoProvider?.getAuthUrl()}
          >
            Войти через SSO
          </Button>
        </div>

        {showError && <div className={styles.error}>Неверный логин или пароль</div>}
      </div>
    </div>
  );
};
