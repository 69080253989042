import {
  EmployeeResponsePrivate,
  PermissionAction,
  PermissionResource,
  UpdateEmployeeAuthPasswordBodyPrivate,
} from '@tests/types';
import { Col, Form, Input, Row } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';

import { Eye, EyeInvisible } from '@/assets';
import { Button, FormItem } from '@/atoms';
import { DrawerWrapper } from '@/components';
import styles from '@/containers/EmployeeEditor/styles.module.scss';
import { usePermissions } from '@/hooks';
import {
  useGetEmployeeAuthPasswordByIdQuery,
  useUpdateEmployeeAuthPasswordMutation,
} from '@/services';
import { ButtonType } from '@/types';

type Props = {
  employee: EmployeeResponsePrivate<string>;
  onClose: () => void;
};

export const EmployeePasswordEditor: React.FC<Props> = ({ employee, onClose }) => {
  const { data: authData } = useGetEmployeeAuthPasswordByIdQuery(
    { id: employee?.id },
    { skip: !usePermissions(PermissionResource.Employee, PermissionAction.Read) || !employee?.id },
  );

  const [updateAuthPassword] = useUpdateEmployeeAuthPasswordMutation();

  const [form] = Form.useForm();

  const { getFieldsValue } = form;

  useEffect(() => {
    if (authData?.login) {
      form.setFieldValue('login', authData.login);
    }
  }, [authData, form]);

  const onFinish = async () => {
    const formValues = getFieldsValue();

    const updateData: UpdateEmployeeAuthPasswordBodyPrivate & { id: number } = {
      id: employee.id,
      login: formValues?.login,
      password: formValues?.password,
    };

    // if (formValues.login) updateData.login = formValues.login;
    // if (formValues.password) updateData.password = formValues.password;

    await updateAuthPassword(updateData);

    onClose();
  };

  return (
    <DrawerWrapper
      title="Логин и пароль"
      open
      handleClose={onClose}
      mobileDimension={768}
      desktopModal
      scrollActive
      modalClassName={styles.editorWrapper}
      footer={
        <div className={styles.editBtnWrapper}>
          <Button
            flex
            type={ButtonType.Secondary}
            className={classNames(styles.editBtn, styles.editCloseBtn)}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            flex
            className={classNames(styles.editBtn, styles.editSaveBtn)}
            onClick={() => form.submit()}
            type={ButtonType.Primary}
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form form={form} className={styles.form} onFinish={onFinish}>
        <Row gutter={[15, 15]} className={styles.inputWrapper}>
          <Col span={24}>
            <FormItem
              flex
              label="Логин"
              name="login"
              className={styles.label}
              rules={[
                {
                  message: 'Пожалуйста, введите логин',
                  required: true,
                },
                {
                  max: 32,
                  message: 'Логин слишком длинный: используйте не более 32 символов',
                },
              ]}
            >
              <Input className={styles.input} placeholder="Введите логин" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              flex
              label="Новый пароль"
              name="password"
              className={styles.label}
              rules={[
                {
                  message: 'Пожалуйста, введите новый пароль',
                  required: true,
                },
                {
                  message: 'Пароль слишком короткий: используйте хотя бы 6 символов',
                  min: 6,
                },
                {
                  max: 32,
                  message: 'Пароль слишком длинный: используйте не более 32 символов',
                },
                {
                  message: (
                    <>
                      Пароль содержит запрещённые символы <br />
                      Для пароля можно использовать только буквы латинского алфавита, цифры и
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      &nbsp;символы: `!@#$%^&*()_=+[]{};:"\\|,.
                    </>
                  ),

                  pattern: /^[0-9A-Za-z`!@#$%^&*()_=+\[\]{};:"\\|,]{1,}$/gm,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value?.length > 0 && getFieldValue('login') === value) {
                      return Promise.reject(new Error('Пароль не должен совпадать с логином'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                className={styles.input}
                rootClassName={styles.inputRoot}
                placeholder="Введите новый пароль"
                // eslint-disable-next-line react/no-unstable-nested-components
                iconRender={(visible) =>
                  visible ? (
                    <Eye className={styles.eyeIcon} />
                  ) : (
                    <EyeInvisible className={styles.eyeIcon} />
                  )
                }
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              flex
              label="Повторите пароль"
              className={styles.label}
              name="confirm"
              dependencies={['password']}
              rules={[
                {
                  message: 'Пожалуйста, подтвердите новый пароль',
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error('Пароли не совпадают'));
                  },
                }),
              ]}
            >
              <Input.Password
                className={styles.input}
                rootClassName={styles.inputRoot}
                placeholder="Повторите новый пароль"
                // eslint-disable-next-line react/no-unstable-nested-components
                iconRender={(visible) =>
                  visible ? (
                    <Eye className={styles.eyeIcon} />
                  ) : (
                    <EyeInvisible className={styles.eyeIcon} />
                  )
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </DrawerWrapper>
  );
};
