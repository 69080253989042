import { PermissionAction, PermissionResource, ProjectResponsePrivate } from '@tests/types';
import { Card as CardItem, Col, Row } from 'antd';
import React, { useCallback } from 'react';

import { NewLogo, Trash } from '@/assets';
import { Badge, Button, message } from '@/atoms';
import { ConfirmModal } from '@/components';
import { usePermissions } from '@/hooks';
import { useDeleteProjectMutation } from '@/services';

import styles from './styles.module.scss';

interface Props {
  onProjectEditClick: (id: number) => void;
  project: ProjectResponsePrivate;
}

export const ProjectCard: React.FC<Props> = (props) => {
  const {
    onProjectEditClick,
    project: { id, logo, name },
  } = props;

  const [deleteProject] = useDeleteProjectMutation();

  const onEditClick = async () => {
    await onProjectEditClick(id);
  };

  const handleDeleteClick = useCallback(async () => {
    ConfirmModal({
      onOk: async () => {
        await deleteProject(id);
        message({
          description: 'Проект удален',
          type: 'success',
        });
      },

      text: 'Выбранный проект будет удалён без возможности восстановления.',
      title: 'Удалить проект?',
    });
  }, [deleteProject, id]);

  return (
    <CardItem bordered={false} className={styles.card} bodyStyle={{ padding: 0 }}>
      <div className={styles.wrapper}>
        <Row>
          <Col>
            <Badge className="project">{name}</Badge>
          </Col>
        </Row>
        <Row justify="center" align="middle" className={styles.logoWrapper}>
          {logo ? (
            <img height={30} src={logo?.url} alt="name" />
          ) : (
            <NewLogo className={styles.newLogo} />
          )}
        </Row>
        <Row justify="space-between" wrap={false} gutter={[5, 0]}>
          <Col flex="1">
            <Button
              className={styles.button}
              disabled={!usePermissions(PermissionResource.Project, PermissionAction.Update)}
              onClick={onEditClick}
            >
              Редактировать
            </Button>
          </Col>
          <Col>
            <Button
              className={styles.button}
              disabled={!usePermissions(PermissionResource.Project, PermissionAction.Delete)}
              onClick={handleDeleteClick}
            >
              <Trash width={24} height={24} />
            </Button>
          </Col>
        </Row>
      </div>
    </CardItem>
  );
};
