import { GetManyRolesQueryPrivate, PermissionAction, PermissionResource } from '@tests/types';
import { inc } from 'ramda';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Select } from '@/atoms';
import { usePermissions } from '@/hooks';
import { rolesEntitySelector } from '@/selectors';
import { useGetRolesListQuery } from '@/services';

type FiltersItem = {
  label: string;
  value: number;
};

type FiltersProps = {
  classname?: string;
  onChange?: Dispatch<SetStateAction<FiltersItem[]>>;
  value?: FiltersItem[];
};

export const RolesFilter: React.FC<FiltersProps> = ({ classname, onChange, value }) => {
  const [rolePage, setRolePage] = useState(0);

  const getRoleListQueryParams = useMemo(() => {
    const params = {
      offset: rolePage * 30,
      relations: ['permissions'],
    } as Partial<GetManyRolesQueryPrivate>;

    return params;
  }, [rolePage]);

  const { data: rolesData, isFetching: isRolesFetching } = useGetRolesListQuery(
    getRoleListQueryParams,
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Role, PermissionAction.Read),
    },
  );
  const roles = useSelector(rolesEntitySelector.selectAll) || [];

  const hasMoreRoles = rolesData?.count > roles?.length;

  const fetchRolesData = async () => {
    setRolePage(inc);
  };

  return (
    <Select
      hasMore={hasMoreRoles}
      fetchData={fetchRolesData}
      loading={isRolesFetching}
      options={roles.map(({ id, name }) => ({ label: name, value: id }))}
      onChange={onChange}
      value={value}
      classname={classname}
    />
  );
};
