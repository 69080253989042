import { Button, Form } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { anyPass, isEmpty, isNil, omit, reject } from 'ramda';
import { FC, useCallback, useEffect, useState } from 'react';

import { DateFilter, FiltersItem, FormItem, ProjectsFilter, RolesFilter, Select } from '@/atoms';
import { DEFAULT_FILTER_EMPLOYEE_KEYS } from '@/constants';
import { useFilterPage, useWindowDimensions } from '@/hooks';
import { isEmptyObject, STATUS_OPTIONS } from '@/utils';

import styles from './styles.module.scss';

interface Props {
  mobileDimension: number;
  onDeletedClear?: () => void;
  onMobileSubmit: () => void;
}

const clearObject = reject(anyPass([isEmpty, isNil]));

const convertProjectsOrEmployees = (entities: string | string[]) => {
  if (entities) {
    if (Array.isArray(entities)) {
      return entities.map((id: string) => ({ value: +id }));
    }

    return [{ value: +entities }];
  }

  return [];
};

const convertDates = (dates: string | string[]) => {
  if (dates) {
    if (Array.isArray(dates)) {
      return dates.map((date: string) => dayjs(date));
    }
  }

  return [];
};

export const EmployeeFilters: FC<Props> = ({ mobileDimension, onDeletedClear, onMobileSubmit }) => {
  const { filter, resetFilter, setFilter } = useFilterPage();

  const windowDimensions = useWindowDimensions();
  const windowWidth = windowDimensions.width;
  const [isMobile, setIsMobile] = useState(windowWidth < mobileDimension);
  useEffect(() => {
    setIsMobile(windowWidth < mobileDimension);
  }, [windowWidth]);

  const [form] = useForm<{
    createdAt: Dayjs[];
    projects: FiltersItem[];
    roleId: FiltersItem;
    status: string;
    updatedAt: Dayjs[];
  }>();

  const RESET_FILTER_KEYS = ['offset', 'page', ...DEFAULT_FILTER_EMPLOYEE_KEYS];
  const data = useWatch([], form);

  const onFinish = useCallback(() => {
    onDeletedClear?.();
    resetFilter({
      ...omit(RESET_FILTER_KEYS, filter),
      ...omit(['projects'], clearObject(data)),
      ...(data.projects.length > 0 ? { projects: data.projects.map((item) => item.value) } : {}),
      ...(data.roleId ? { roleId: data.roleId } : {}),
      ...(data?.createdAt?.length > 0
        ? { createdAt: data.createdAt.map((item) => item.toISOString()) }
        : {}),
      ...(data?.updatedAt?.length > 0
        ? { updatedAt: data.updatedAt.map((item) => item.toISOString()) }
        : {}),
    });
    if (isMobile) {
      onMobileSubmit();
    }
  }, [data, setFilter, isMobile]);

  const handleReset = () => {
    onDeletedClear?.();
    resetFilter(omit(RESET_FILTER_KEYS, filter));
    if (isMobile) {
      onMobileSubmit();
    }
  };

  const initialValues = {
    createdAt: convertDates(filter?.createdAt),
    projects: convertProjectsOrEmployees(filter?.projects),
    roleId: filter?.roleId ? +filter.roleId : undefined,
    status: filter?.status,
    updatedAt: convertDates(filter?.updatedAt),
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className={styles.filtersForm}
      initialValues={initialValues}
    >
      <div className={styles.container}>
        <FormItem name="createdAt" label="Период создания" flex>
          <DateFilter />
        </FormItem>
        <FormItem name="updatedAt" label="Период изменения" flex>
          <DateFilter />
        </FormItem>
        <FormItem name="status" label="Статус" flex>
          <Select options={STATUS_OPTIONS} />
        </FormItem>
        <FormItem name="projects" label="Проект" flex>
          <ProjectsFilter />
        </FormItem>
        <FormItem name="roleId" label="Роль" flex>
          <RolesFilter />
        </FormItem>
      </div>
      <div className={styles.filtersBtnWrapper}>
        <Button
          className={classNames(styles.filtersBtn, styles.resetFilters)}
          disabled={isEmptyObject(data)}
          onClick={handleReset}
          htmlType="reset"
        >
          Сбросить
        </Button>

        <Button
          htmlType="submit"
          className={classNames(styles.filtersBtn, styles.filtersSubmitBtn)}
          type="primary"
        >
          Применить
        </Button>
      </div>
    </Form>
  );
};
