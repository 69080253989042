import { BackgroundPlace, TestType } from '@tests/types';
import { Form, Input } from 'antd';
import React from 'react';

import { DropZone } from '@/atoms';
import { InteractiveImages } from '@/components';

import { DropType } from '../../../constants';
import { useGetValidationError } from '../../../hooks';
import { BackgroundPlaces } from '../BackgroundPlaces';
import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

type Props = {
  backgroundFormName?: string;
  backgroundImage: any;
  backgroundImageId: number;
  backgroundPlace?: BackgroundPlace[];
  caption?: string;
  captionFormName?: string;
  dropType: DropType;
  dropzonePlaceholder?: string;
  id: number;
  mediaCaptionMaxLength?: number;
  rootClassName?: string;
  testId?: number;
  testType?: TestType;
  title?: string;
  updateBlock: any;
  withCaption?: boolean;
};

export const ImageBlock: React.FC<Props> = ({
  backgroundFormName = 'backgroundImageId',
  backgroundImage,
  backgroundImageId,
  backgroundPlace,
  caption,
  captionFormName = 'mediaCaption',
  dropType,
  dropzonePlaceholder = 'Добавьте обложку',
  id,
  mediaCaptionMaxLength,
  rootClassName,
  testId,
  testType,
  title = 'Обложка',
  updateBlock,
  withCaption = false,
}) => {
  const [form] = Form.useForm();

  const { setFieldValue, submit } = form;

  const backgroundError = useGetValidationError({ field: backgroundFormName, targetId: id });

  const handleBackgroundClick = (backgroundId: number) => {
    setFieldValue(backgroundFormName, backgroundId);
    submit();
  };

  return (
    <FormBlock
      testId={id}
      blockTitle={title}
      rootClassName={rootClassName}
      form={form}
      updateBlock={updateBlock}
    >
      <Form.Item
        name={backgroundFormName}
        getValueFromEvent={(value) => value?.id ?? null}
        getValueProps={() => ({
          value: backgroundImage,
        })}
      >
        <DropZone
          dropType={dropType}
          rootClassName={styles.dropZoneRoot}
          className={styles.dropZone}
          placeholder={dropzonePlaceholder}
          validErrorMessage={backgroundError?.message}
        />
      </Form.Item>

      {withCaption && (
        <Form.Item name={captionFormName} className={styles.inputRow} initialValue={caption}>
          <Input
            className={styles.input}
            rootClassName={styles.inputRoot}
            placeholder="Подпись к изображению"
            maxLength={mediaCaptionMaxLength}
          />
        </Form.Item>
      )}

      {backgroundPlace && (
        <BackgroundPlaces
          backgroundPlace={backgroundPlace}
          testType={testType}
          className={styles.backgroundPlaces}
        />
      )}

      <InteractiveImages
        onSelectImage={handleBackgroundClick}
        selectedImageId={backgroundImageId}
        testId={testId}
      />
    </FormBlock>
  );
};
