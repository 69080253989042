import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Typography } from 'antd';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IProps {
  onClose: () => void;
  onDelete: () => void;
}

export const HighlightDeleteModal: React.FC<IProps> = ({ onClose, onDelete }) => (
  <Modal
    open
    getContainer={() => document.getElementById('modal-container')}
    centered
    onClose={onClose}
    onCancel={onClose}
    footer={null}
    mask={false}
    closable={false}
    width={400}
    destroyOnClose
    rootClassName={styles.modalWrapper}
    // eslint-disable-next-line react/no-unstable-nested-components
    modalRender={(node) => <div>{node}</div>}
  >
    <Col className={styles.modal}>
      <Row className={styles.modalTitle} justify="space-between" align="middle">
        <Col>
          <Typography.Text className={styles.modalTitleText}>Удалить интерактивы?</Typography.Text>
        </Col>
        <Col>
          <CloseOutlined onClick={onClose} />
        </Col>
      </Row>
      <Row>
        <Typography.Text className={styles.modalText}>
          Выбранные интерактивы будут удалены.
        </Typography.Text>
      </Row>
      <Row gutter={[10, 0]} className={styles.btnWrapper} justify="space-between">
        <Col xs={12}>
          <Button className={classNames(styles.btn, styles.closeBtn)} onClick={onClose}>
            Отменить
          </Button>
        </Col>
        <Col xs={12}>
          <Button
            className={classNames(styles.btn, styles.deleteBtn)}
            onClick={onDelete}
            type="primary"
          >
            Удалить
          </Button>
        </Col>
      </Row>
    </Col>
  </Modal>
);
