import { PermissionAction, PermissionResource, RoleResponsePrivate } from '@tests/types';
import { Col, Row, Spin, Typography } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RoleRow } from '@/atoms';
import { InfiniteScrollComponent } from '@/components';
import { usePermissions } from '@/hooks';
import { rolesEntitySelector } from '@/selectors';
import { useGetRolesListQuery } from '@/services';
import { isDefined } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  isMobile: boolean;
  onEditClick: (role: RoleResponsePrivate<string>) => void;
};

const RoleFieldsRow = () => (
  <Row align="middle" justify="space-between" className={styles.fieldsRow}>
    <Col span={4}>
      <Typography.Text type="secondary">Роль</Typography.Text>
    </Col>
    <Col span={18}>
      <Typography.Text type="secondary">Статус</Typography.Text>
    </Col>
    <Col span={1} />
  </Row>
);

export const RolesList: React.FC<Props> = ({ isMobile, onEditClick }) => {
  const [page, setPage] = useState(0);

  const fetchData = async () => {
    setPage(page + 1);
  };

  const { data, isFetching, isLoading } = useGetRolesListQuery(
    {
      offset: page * 30,
      relations: ['permissions'],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Role, PermissionAction.Read),
    },
  );

  const roles = useSelector(rolesEntitySelector.selectAll) || [];

  if (page === 0 && (isFetching || isLoading)) {
    return (
      <Spin
        size="large"
        style={{
          padding: '10px',
          width: '100%',
        }}
      />
    );
  }

  if (roles?.length === 0) {
    return (
      <Typography.Text className={styles.stub}>
        По вашему запросу ничего не найдено.
      </Typography.Text>
    );
  }

  return (
    <InfiniteScrollComponent
      hasMore={isDefined(data) && data?.count > roles?.length}
      length={roles?.length || 0}
      fetchData={fetchData}
      offset={page * 30}
    >
      <div className={styles.rowsContainer}>
        <RoleFieldsRow />
        {roles?.map((role) => (
          <RoleRow role={role} isMobile={isMobile} onEditClick={onEditClick} />
        ))}
      </div>
    </InfiniteScrollComponent>
  );
};
