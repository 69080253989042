import { CloseOutlined } from '@ant-design/icons';
import { DatePicker, Form } from 'antd';
import classNames from 'classnames';
import React, { ComponentProps, Dispatch } from 'react';

import { Calendar } from '@/assets';

import styles from './styles.module.scss';

type Props = {
  isModal?: boolean;
  name: string;
  selectedDate: any;
  setSelectedDate: Dispatch<any>;
};

type RangeValue = Parameters<
  NonNullable<ComponentProps<typeof DatePicker.RangePicker>['onChange']>
>[0];

const { RangePicker } = DatePicker;

export const DateFilter: React.FC<Props> = ({ isModal, name, selectedDate, setSelectedDate }) => {
  const formatDateToIso = (values: RangeValue) => {
    if (values) {
      const startDate = values[0]?.toISOString();
      const endDate = values[1]?.toISOString();

      return [startDate, endDate];
    }

    return null;
  };

  const handleSelectedDateChange = (values: RangeValue) => {
    const formatDate = formatDateToIso(values);
    setSelectedDate(formatDate);
  };

  return (
    <Form.Item name={name}>
      <RangePicker
        size="large"
        placeholder={['Выбрать', '']}
        className={classNames(styles.selector, styles.modal)}
        onChange={(dates) => {
          if (dates?.length === 2) {
            handleSelectedDateChange([dates[0], dates[1].endOf('day')]);
          } else {
            handleSelectedDateChange(null);
          }
        }}
        separator={selectedDate ? '-' : ''}
        clearIcon={<CloseOutlined />}
        suffixIcon={<Calendar />}
        inputReadOnly
        popupClassName={styles.picker}
      />
    </Form.Item>
  );
};
