import { createSlice } from '@reduxjs/toolkit';
import { EmployeeResponsePrivate } from '@tests/types';

import { employeesApi } from '@/services';

import { employeesAdapter } from '../adapters';

interface State {
  changes?: Partial<EmployeeResponsePrivate>[];
  count: number;
  lastRequestId?: string;
}

export const employeesSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(employeesApi.endpoints.getEmployeesList.matchPending, (state, action) => {
        if (
          (action.meta.arg?.originalArgs?.offset === 0 || !action.meta.arg?.originalArgs?.offset) &&
          action.meta.arg.subscribe
        ) {
          employeesAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(employeesApi.endpoints.getEmployeesList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          employeesAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
          state.changes = [];
        }
      })
      .addMatcher(employeesApi.endpoints.getEmployeeById.matchFulfilled, (state, action) => {
        employeesAdapter.upsertOne(state, action.payload);
      });
  },
  initialState: employeesAdapter.getInitialState<State>({
    changes: [],
    count: 0,
  }),
  name: 'employees',
  reducers: {},
});

export const employeesReducer = employeesSlice.reducer;
