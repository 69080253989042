import { CloseOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import classNames from 'classnames';

import { ArrowDown } from '@/assets';
import { handlePopupScroll } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  classname?: string;
  fetchData?: () => Promise<void>;
  hasMore?: boolean;
  isModal?: boolean;
  loading?: boolean;
  onChange: (
    value: any,
    option?:
      | {
          label: string;
          value: string | number;
        }
      | {
          label: string;
          value: string | number;
        }[],
  ) => void;
  options: { label: string; value: string | number }[];
  value: any;
};

export const SelectRender: React.FC<Props> = ({
  classname,
  fetchData,
  hasMore,
  isModal,
  loading,
  onChange,
  options,
  value,
}) => (
  <Select
    size="large"
    popupClassName={styles.selectorOptions}
    dropdownStyle={{ backgroundColor: 'var(--color-grey-lvl1)' }}
    notFoundContent={loading ? <Spin size="small" /> : null}
    allowClear={!isModal}
    onClear={() => onChange(null)}
    options={options}
    value={value}
    onChange={onChange}
    suffixIcon={<ArrowDown className={styles.icon} />}
    clearIcon={<CloseOutlined />}
    className={classNames(styles.selector, styles[classname])}
    onPopupScroll={(e) => handlePopupScroll(e, fetchData, hasMore)}
    placeholder="Выбрать"
  />
);
