import { PlusOutlined } from '@ant-design/icons';
import { AnswerType, TestResponsePrivate } from '@tests/types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@/atoms';
import { useDisabledByUpdate } from '@/hooks';
import { countQuestionsSelector, questionsItemsSelector } from '@/selectors';
import { useCreateQuestionMutation } from '@/services';
import { ButtonType } from '@/types';

import styles from './styles.module.scss';

type Props = {
  onClick?: () => void;
  test: TestResponsePrivate;
};

export const AddQuestionButton: React.FC<Props> = ({ onClick, test }) => {
  const questions = useSelector(questionsItemsSelector);
  const questionsCount = useSelector(countQuestionsSelector);
  const disabled = useDisabledByUpdate();
  const [createQuestion] = useCreateQuestionMutation();

  const { id } = test;

  const handleAddQuestion = useCallback(async () => {
    await createQuestion({ answerType: AnswerType.Text, sortOrder: questionsCount, testId: id });
    if (onClick) {
      onClick();
    }
  }, [createQuestion, id, questionsCount]);

  const questionsLimit = 15;

  return (
    <>
      {questions.length < questionsLimit && (
        <div className={styles.addQuestionButtonWrapper}>
          <Button disabled={disabled} type={ButtonType.Primary} onClick={handleAddQuestion} flex>
            <PlusOutlined /> Добавить вопрос
          </Button>
        </div>
      )}
    </>
  );
};
