import { EmployeeResponsePrivate } from '@tests/types';
import { Col, Form, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Button, DropZone, FormItem, ProjectsFilter, RolesFilter, Select } from '@/atoms';
import { DrawerWrapper } from '@/components';
import { DropType, DropZoneType } from '@/constants';
import { rolesEntitySelector } from '@/selectors';
import { useCreateEmployeeMutation, useUpdateEmployeeMutation } from '@/services';
import { ButtonType, FiltersItem } from '@/types';
import { STATUS_OPTIONS } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  employee: EmployeeResponsePrivate<string>;
  employeesRefetch: () => void;
  onClose: () => void;
};

export const EmployeeEditor: React.FC<Props> = ({ employee, employeesRefetch, onClose }) => {
  const [form] = useForm();
  const initialValues = employee
    ? {
        avatar: employee.avatar,
        name: employee.name,
        projects: employee?.projects.map(({ id, name }) => ({ label: name, value: id })),
        role: employee.role.id,
        status: employee.status,
      }
    : {};

  const [updateEmployee] = useUpdateEmployeeMutation();
  const [createEmployee] = useCreateEmployeeMutation();

  const roles = useSelector(rolesEntitySelector.selectAll) || [];
  const roleOptions = roles.map(({ id, name }) => ({ label: name, value: id }));

  const handleSaveEmployee = async () => {
    const data = form.getFieldsValue();
    if (employee) {
      await updateEmployee({
        avatarId: data.avatar?.id || null,
        id: employee.id,
        name: data.name,
        projectIds: data.projects?.map((item: FiltersItem) => item.value),
        roleId: data.role,
        status: data.status,
      });
    } else {
      await createEmployee({
        avatarId: data.avatar?.id || null,
        name: data.name,
        projectIds: data.projects?.map((item: FiltersItem) => item.value),
        roleId: data.role,
      });
    }
    employeesRefetch();
    onClose();
  };

  return (
    <DrawerWrapper
      title={employee ? 'Редактирование сотрудника' : 'Добавление сотрудника'}
      open
      handleClose={onClose}
      mobileDimension={768}
      desktopModal
      scrollActive
      modalClassName={styles.editorWrapper}
      footer={
        <div className={styles.editBtnWrapper}>
          <Button
            flex
            type={ButtonType.Secondary}
            className={classNames(styles.editBtn, styles.editCloseBtn)}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            flex
            className={classNames(styles.editBtn, styles.editSaveBtn)}
            onClick={() => form.submit()}
            type={ButtonType.Primary}
          >
            {employee ? 'Сохранить' : 'Добавить'}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        className={styles.form}
        onFinish={handleSaveEmployee}
        initialValues={initialValues}
      >
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <Form.Item noStyle name="avatar">
              <DropZone
                dropType={DropType.Image}
                type={DropZoneType.Avatar}
                rootClassName={styles.dropZoneRoot}
                className={styles.dropZone}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormItem flex label="ФИО" name="name" className={styles.label}>
              <Input className={styles.input} placeholder="Введите имя сотрудника" />
            </FormItem>
          </Col>
          {employee && (
            <>
              <Col span={24}>
                <Row justify="space-between" className={styles.idWrapper}>
                  <Col>
                    <Typography.Text type="secondary">ID:</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text>{employee.id}</Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <FormItem flex label="Статус" name="status" className={styles.label}>
                  <Select options={STATUS_OPTIONS} classname={styles.select} />
                </FormItem>
              </Col>
            </>
          )}
          <Col span={24}>
            <FormItem flex label="Роль" className={styles.label} name="role">
              <RolesFilter />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem flex label="Проекты" name="projects" className={styles.label}>
              <ProjectsFilter classname={styles.select} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </DrawerWrapper>
  );
};
