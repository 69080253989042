import { always, cond, T } from 'ramda';

import { isCrossword, isExtendedTest, isTest } from './test';

export const getResultsMaxLength = cond([
  [isExtendedTest, always(12)],
  [isTest, always(12)],
  [isCrossword, always(0)],
  [T, always(10)],
]);
