import { createSlice } from '@reduxjs/toolkit';
import { compose, equals, not } from 'ramda';

import { resultsAdapter } from '@/adapters';
import { resultsApi } from '@/services';

interface State {
  count: number;
  lastRequestId?: string;
  resultsUpdating: number[];
}

export const resultsSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(resultsApi.endpoints.getResultsList.matchPending, (state, action) => {
        if (action.meta.arg.subscribe) {
          resultsAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(resultsApi.endpoints.getResultsList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          resultsAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
        }
      })
      .addMatcher(resultsApi.endpoints.updateResult.matchPending, (state, action) => {
        state.resultsUpdating = [...state.resultsUpdating, action.meta.arg.originalArgs.id];
      })
      .addMatcher(resultsApi.endpoints.updateResult.matchFulfilled, (state, action) => {
        state.resultsUpdating = state.resultsUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs.id)),
        );
      })
      .addMatcher(resultsApi.endpoints.updateResult.matchRejected, (state, action) => {
        state.resultsUpdating = state.resultsUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs.id)),
        );
      })
      .addMatcher(resultsApi.endpoints.deleteResult.matchPending, (state, action) => {
        state.resultsUpdating = [...state.resultsUpdating, action.meta.arg.originalArgs];
      })
      .addMatcher(resultsApi.endpoints.deleteResult.matchFulfilled, (state, action) => {
        state.resultsUpdating = state.resultsUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs)),
        );
        resultsAdapter.removeAll(state);
      });
  },
  initialState: resultsAdapter.getInitialState<State>({
    count: 0,
    resultsUpdating: [],
  }),
  name: 'results',
  reducers: {},
});

export const resultsReducer = resultsSlice.reducer;
