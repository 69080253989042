import {
  GetManyStatsResponsePrivate,
  StatDataDevices,
  StatDataRegions,
  StatDataSources,
  StatDataUserClickShowAnswers,
  StatDataUserFinishes,
  StatDataUserFinishesDuration,
  StatDataUserPerQuestion,
  StatDataUserStarts,
  StatType,
  TestResponsePrivate,
  TestType,
} from '@tests/types';
import React, { FC, useMemo } from 'react';

import { UsersStats } from '@/assets';
import { Separator } from '@/atoms';
import { StatsInfoText, StatsPieChart, StatsQuestionsBlock } from '@/components';
import { formatDuration, formatNumberWithSpaces } from '@/utils';

import { ContentBlock } from '../ContentBlock';
import { StatsInfoBlock } from '../StatsInfoBlock';
import styles from './styles.module.scss';

interface Props {
  statData: GetManyStatsResponsePrivate<string>;
  test: TestResponsePrivate<string>;
}

const isNotEmtyArray = (array: any[]) => array.length > 0;

export const TestStatsContainer: FC<Props> = ({ statData, test }) => {
  const userStarts = useMemo(() => {
    const data = (statData?.results.find((item) => item.type === StatType.UserStarts)?.data || {
      count: 0,
    }) as StatDataUserStarts;

    return formatNumberWithSpaces(data.count);
  }, [statData]);

  const userFinishes = useMemo(() => {
    const data = (statData?.results.find((item) => item.type === StatType.UserFinishes)?.data || {
      count: 0,
    }) as StatDataUserFinishes;

    return formatNumberWithSpaces(data.count);
  }, [statData]);

  const userClickShowAnswers = useMemo(() => {
    const data = (statData?.results.find((item) => item.type === StatType.UserClickShowAnswers)
      ?.data || {
      count: 0,
    }) as StatDataUserClickShowAnswers;

    return formatNumberWithSpaces(data.count);
  }, [statData]);

  const userFinishesDuration = useMemo(() => {
    const data = (statData?.results.find((item) => item.type === StatType.UserFinishesDuration)
      ?.data || { duration: 0 }) as StatDataUserFinishesDuration;

    return formatDuration(data?.duration);
  }, [statData]);

  const userPerQuestion = useMemo(() => {
    const data = (statData?.results.find((item) => item.type === StatType.UserPerQuestion)
      ?.data || {
      items: Array.from({ length: test?.questionsCount || 0 }).fill(0),
    }) as StatDataUserPerQuestion;

    return data;
  }, [statData, test]);

  const devices = useMemo(() => {
    const data = (statData?.results.find((item) => item.type === StatType.Devices)?.data || {
      items: [],
    }) as StatDataDevices;

    return data;
  }, [statData]);

  const regions = useMemo(() => {
    const data = (statData?.results.find((item) => item.type === StatType.Regions)?.data || {
      items: [],
    }) as StatDataRegions;

    return data;
  }, [statData]);

  const sources = useMemo(() => {
    const data = (statData?.results.find((item) => item.type === StatType.Sources)?.data || {
      items: [],
    }) as StatDataSources;

    return data;
  }, [statData]);

  return (
    <>
      {statData?.count > 0 && (
        <>
          <Separator />
          <ContentBlock title="Общая информация">
            <div className={styles.statsTop}>
              <div className={styles.grid}>
                <StatsInfoBlock title="Среднее время прохождения">
                  <StatsInfoText text={userFinishesDuration} />
                </StatsInfoBlock>
                <StatsInfoBlock title="Начали прохождение">
                  <StatsInfoText text={userStarts} icon={<UsersStats />} />
                </StatsInfoBlock>
                <StatsInfoBlock title="Завершили прохождение">
                  <StatsInfoText text={userFinishes} icon={<UsersStats />} />
                </StatsInfoBlock>
                {test.type === TestType.Crossword && (
                  <StatsInfoBlock title="Нажали кнопку «Ответы»">
                    <StatsInfoText text={userClickShowAnswers} icon={<UsersStats />} />
                  </StatsInfoBlock>
                )}
              </div>
              {[TestType.Quiz, TestType.Test].includes(test.type) && (
                <StatsInfoBlock title="Количество пользователей на каждом вопросе" noPadding>
                  <StatsQuestionsBlock test={test} userPerQuestion={userPerQuestion} />
                </StatsInfoBlock>
              )}
            </div>
          </ContentBlock>
          {(isNotEmtyArray(devices.items) ||
            isNotEmtyArray(regions.items) ||
            isNotEmtyArray(sources.items)) && (
            <ContentBlock title="Информация о трафике">
              <div className={styles.statsBottom}>
                {isNotEmtyArray(sources.items) && (
                  <StatsInfoBlock title="Источник трафика">
                    <StatsPieChart data={sources.items} />
                  </StatsInfoBlock>
                )}
                {isNotEmtyArray(regions.items) && (
                  <StatsInfoBlock title="Регион">
                    <StatsPieChart data={regions.items} />
                  </StatsInfoBlock>
                )}
                {isNotEmtyArray(devices.items) && (
                  <StatsInfoBlock title="Типы устройств">
                    <StatsPieChart data={devices.items} />
                  </StatsInfoBlock>
                )}
              </div>
            </ContentBlock>
          )}
        </>
      )}
    </>
  );
};
