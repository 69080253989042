import { UploadFileStatus } from 'antd/es/upload/interface';
import classNames from 'classnames';
import React from 'react';

import { User } from '@/assets';

import { DropZonePreviewIcon } from '../../DropZonePreviewIcon';
import styles from './styles.module.scss';

interface Props {
  progress?: number;
  title?: string;
  uploadState?: UploadFileStatus | 'converting' | null;
}

export const DropZonePreviewAvatar = ({ progress, title, uploadState }: Props) => (
  <div className={classNames(styles.preview)}>
    <DropZonePreviewIcon
      progress={progress}
      uploadState={uploadState}
      noText
      icon={<User className={styles.icon} />}
    />
  </div>
);
