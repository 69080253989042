export const DEFAULT_FILTER_KEYS = [
  'createdBy',
  'publishedAt',
  'createdAt',
  'status',
  'projects',
  'deleted',
];

export const DEFAULT_FILTER_EMPLOYEE_KEYS = [
  'createdAt',
  'updatedAt',
  'status',
  'projects',
  'roleId',
];
