import { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppLayout } from '@/layouts';
import { isAuthenticatedSelector, userRoleSelector } from '@/selectors';

type AuthGuardProps = PropsWithChildren & {
  isPublic?: boolean;
};

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const role = useSelector(userRoleSelector);
  const { children, isPublic } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPublic && !isAuthenticated) {
      window.location.href = '/login';
    }
  }, [isPublic, isAuthenticated, navigate, role]);

  if (isPublic) {
    return <>{children}</>;
  }

  return <AppLayout>{children}</AppLayout>;
};
