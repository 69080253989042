import { Form, InputNumber, Slider } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import React, { useCallback, useState } from 'react';

import { isDefined } from '@/utils';

import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

type Props = {
  blockTitle: string;
  formItemName?: string;
  id: number;
  max: number;
  min: number;
  updateBlock: any;
  value: number;
};

const setMaxMinValue = (value: number, max: number, min: number) => {
  if (value > max) {
    return max;
  }
  if (value < min) {
    return min;
  }

  return value;
};

export const SliderBlock: React.FC<Props> = ({
  blockTitle,
  formItemName = '',
  id,
  max = 100,
  min = 0,
  updateBlock,
  value = 0,
}) => {
  const [form] = Form.useForm();
  const { setFieldValue } = form;
  const [timeout, setTimeout] = useState(value);

  const handleValueChange = useCallback(
    (newValue: number | null) => {
      const validValue = typeof newValue === 'number' ? newValue : 0;
      setTimeout(+validValue);
      setFieldValue(formItemName, +validValue);
    },
    [formItemName, setFieldValue],
  );

  const onValuesChange = useCallback(
    ({ timeout: changedTimeout }: { timeout: number }) => {
      if (!isDefined(changedTimeout)) {
        setFieldValue('timeout', 0);
        setTimeout(0);
      }
    },
    [setFieldValue],
  );

  return (
    <FormBlock
      blockTitle={blockTitle}
      testId={id}
      form={form}
      initialValues={{ [formItemName]: timeout }}
      updateBlock={updateBlock}
      onValuesChange={onValuesChange}
    >
      <div className={styles.container}>
        <Form.Item name={formItemName} noStyle>
          <Slider
            min={min}
            max={max}
            onChange={handleValueChange}
            value={typeof timeout === 'number' ? timeout : 0}
            className={styles.slider}
          />
        </Form.Item>
        <Form.Item
          name={formItemName}
          noStyle
          getValueFromEvent={(event) => setMaxMinValue(+event.target.value, max, min)}
        >
          <div className={styles.inputWrapper}>
            <InputNumber
              min={min}
              max={max}
              onChange={handleValueChange}
              value={typeof timeout === 'number' ? timeout : 0}
              className={styles.input}
            />
            <div className={styles.inputWrapperPlaceholder}>секунд</div>
          </div>
        </Form.Item>
      </div>
    </FormBlock>
  );
};
