import { PermissionAction, PermissionResource } from '@tests/types';
import { isEmpty } from 'ramda';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { MultiSelect } from '@/components';
import { usePermissions } from '@/hooks';

type FiltersItem = {
  label: string;
  value: number;
};

type EntityFilterProps = {
  checkedItems: FiltersItem[];
  classname?: string;
  entitySelector: (state: any) => any[];
  isModal?: boolean;
  permissionResource: PermissionResource;
  setCheckedItems: Dispatch<SetStateAction<FiltersItem[]>>;
  useEntityListQuery: any;
};

export const EntityFilter: React.FC<EntityFilterProps> = ({
  checkedItems,
  classname,
  entitySelector,
  permissionResource,
  setCheckedItems,
  useEntityListQuery,
}) => {
  const [page, setPage] = useState(0);

  const { data: entityData, isFetching } = useEntityListQuery(
    {
      offset: page * 30,
      order: '+name',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(permissionResource, PermissionAction.Read),
    },
  );

  // TODO продумать очистку стора при анмаунте компонентов
  const entities =
    useSelector(entitySelector)?.map((item) => ({
      label: item.name,
      value: item.id,
    })) || [];

  const isValueReady = useMemo(
    () => !isEmpty(entities) && !isEmpty(checkedItems),
    [checkedItems, entities],
  );

  const hasMore = entityData && entityData?.count > entities?.length;

  const fetchData = async () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleCheckItems = (values: FiltersItem[]) => {
    setCheckedItems(values);
  };

  return (
    <MultiSelect
      value={isValueReady ? checkedItems : []}
      loading={isFetching || isEmpty(entities)}
      options={entities}
      hasMore={hasMore}
      fetchData={fetchData}
      onChange={handleCheckItems}
      classname={classname}
    />
  );
};
