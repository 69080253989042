import { Col, Modal, Row, Typography } from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { CloseIcon as Close } from '@/assets';
import { Button } from '@/atoms';
import { ButtonType } from '@/types';

import styles from './styles.module.scss';

interface confirmModalProps {
  cancelText?: string;
  icon?: ReactNode;
  okText?: string;
  onCancel?: () => void;
  onOk: () => void;
  text?: string | ReactNode;
  title?: string | ReactNode;
}

const { confirm } = Modal;

const CloseIcon = () => (
  <div className={styles.modalClose}>
    <Close />
  </div>
);

export const ConfirmModal = (props: confirmModalProps) => {
  const {
    cancelText = 'Отменить',
    icon,
    okText = 'Удалить',
    onCancel,
    onOk,
    text,
    title = 'Подтвердите дейтсвие',
  } = props;

  const handleCloseConfirm = () => {
    if (onCancel) {
      onCancel();
    }
    Modal.destroyAll();
  };

  const handleSuccessConfirm = async () => {
    Modal.destroyAll();
    onOk();
  };

  return confirm({
    cancelButtonProps: { style: { display: 'none' } },
    cancelText: '',
    className: styles.modalContainer,
    closable: true,
    closeIcon: <CloseIcon />,
    content: (
      <Row gutter={[0, 25]} className={styles.modalContent}>
        {text && (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Typography.Text className={styles.text}>{text}</Typography.Text>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <div className={styles.modalFooter}>
            <Button
              type={ButtonType.Secondary}
              className={styles.button}
              flex
              onClick={handleCloseConfirm}
            >
              {cancelText}
            </Button>
            <Button
              type={ButtonType.Primary}
              className={styles.button}
              flex
              onClick={handleSuccessConfirm}
            >
              {okText}
            </Button>
          </div>
        </Col>
      </Row>
    ),
    icon: <div className={classNames(styles.iconWrapper, icon && styles.icon)}>{icon}</div>,
    maskClosable: true,
    okButtonProps: { style: { display: 'none' } },
    okText: '',
    onCancel: () => handleCloseConfirm(),
    title,
    wrapClassName: classNames(styles.modalWrapper, !text && styles.modalWrapperWithoutText),
  });
};
