import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  label: string;
  testCount: number;
}

export const TabHeadItem: FC<Props> = ({ className, label, testCount }) => (
  <div className={classNames(styles.tabHeadItem, styles[className])}>
    <div className={styles.label}>{label}</div>
    <div className={styles.testCount}>{testCount}</div>
  </div>
);
