import { CloseOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import React, { Dispatch } from 'react';

import { Calendar } from '@/assets';

import styles from './styles.module.scss';

type Props = {
  onChange?: Dispatch<any>;
  value?: any;
};

const { RangePicker } = DatePicker;

export const DateFilter: React.FC<Props> = ({ onChange, value }) => (
  <RangePicker
    size="large"
    placeholder={['Выбрать', '']}
    className={classNames(styles.selector, value?.length === 2 && styles.filled)}
    onChange={(values) => {
      if (values) {
        onChange?.([values[0].startOf('day'), values[1].endOf('day')]);
      } else onChange?.([]);
    }}
    separator={value?.length === 2 ? <div>-</div> : ''}
    allowClear={{ clearIcon: <CloseOutlined /> }}
    inputReadOnly
    suffixIcon={<Calendar />}
    value={value}
    style={{ backgroundColor: 'var(--color-grey-lvl2) !important' }}
    popupClassName={styles.picker}
  />
);
