import { Form as AntForm, FormItemProps } from 'antd';
import classNames from 'classnames';
import React from 'react';

import styles from './style.module.scss';

const CustomLabel = (props: {
  children: React.ReactNode;
  className?: string;
  required?: boolean;
}) => {
  const { children, className, required } = props;

  return (
    <div className={classNames(styles.label, className)}>
      {children} {required && <span>*</span>}
    </div>
  );
};

type Props = FormItemProps & {
  flex?: boolean;
};

export const FormItem: React.FC<Props> = (props) => {
  const { children, className, flex = false, required, ...antProps } = props;

  return (
    <AntForm.Item
      {...antProps}
      label={
        antProps?.label ? (
          <CustomLabel className={className} required={required}>
            {antProps.label}
          </CustomLabel>
        ) : (
          ''
        )
      }
      required={false}
      className={classNames(flex && styles.flex, className)}
    >
      {children}
    </AntForm.Item>
  );
};
