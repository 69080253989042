import { ResultResponsePrivate } from '@tests/types';
import { Modal } from 'antd';
import React, { FC, useMemo } from 'react';

import { useGetQuestionsListQuery } from '../../services';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  results: ResultResponsePrivate[];
  testId: number;
}

enum AnswerPoints {
  First = '+1',
  Second = '+2',
}

export const AnswerResultModal: FC<Props> = ({ onClose, results, testId }) => {
  const { data: { results: questions = [] } = {}, isLoading } = useGetQuestionsListQuery(
    { limit: 100, offset: 0, relations: ['media', 'answers', 'answers.results'], test: testId },
    {
      refetchOnMountOrArgChange: true,
      skip: !testId,
    },
  );

  const resultsList = useMemo(() => {
    const items: Record<
      string,
      { items?: { answerIndex: number; title: string }[]; title?: string }[]
    > = { [AnswerPoints.First]: [], [AnswerPoints.Second]: [] };

    questions?.forEach((question, questionIndex) => {
      const answerItems: Record<string, { answerIndex: number; title: string }[]> = {
        [AnswerPoints.First]: [],
        [AnswerPoints.Second]: [],
      };
      const sortedAnswers = [].concat(question?.answers).sort((a, b) => a.id - b.id);

      sortedAnswers?.forEach((answer, answerIndex) => {
        answer.results?.forEach((result: { points: number; resultId: number }) => {
          if (result.resultId) {
            const resultIndex = results.findIndex((res) => res.id === result.resultId);
            if (resultIndex !== -1) {
              answerItems[`+${result.points}`].push({
                answerIndex: answerIndex + 1,
                title: answer?.text || `Ответ №${answerIndex + 1}`,
              });
            }
          }
        });
      });

      Object.keys(answerItems).forEach((key) => {
        if (answerItems[key].length > 0) {
          items[key].push({
            items: answerItems[key],
            title: `Вопрос №${questionIndex + 1}${question.text ? `: ${question.text}` : ''}`,
          });
        }
      });
    });

    return items;
  }, [questions, results]);

  return (
    <Modal
      title={<span className={styles.modalTitle}>Привязанные ответы</span>}
      open
      onClose={onClose}
      onCancel={onClose}
      loading={isLoading}
      footer={null}
      // eslint-disable-next-line react/no-unstable-nested-components
      modalRender={(node) => <div>{node}</div>}
      className={styles.modal}
    >
      <div className={styles.header}>
        <div className={styles.headerCard}>{AnswerPoints.Second}</div>
        <div className={styles.headerCard}>{AnswerPoints.First}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.bodyCol}>
          {resultsList[AnswerPoints.Second].map((item) => (
            <div className={styles.bodyBlock}>
              <div className={styles.bodyBlockTitle}>{item.title}</div>
              {item.items.map((answer) => (
                <div className={styles.bodyBlockItem}>
                  <span title={answer.title}>
                    {answer.answerIndex}. {answer.title}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.bodyCol}>
          {resultsList[AnswerPoints.First].map((item) => (
            <div className={styles.bodyBlock}>
              <div className={styles.bodyBlockTitle}>{item.title}</div>
              {item.items.map((answer) => (
                <div className={styles.bodyBlockItem} title={answer.title}>
                  {answer.answerIndex}. {answer.title}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
