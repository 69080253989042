import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { FilterItemResponsePrivate, TestValidationError } from '@tests/types';
import { pipe, prop } from 'ramda';

import { testsAdapter } from '@/adapters';

import { stateSelector } from './state';

export const testsEntitySelector = testsAdapter.getSelectors<RootState>(prop('tests'));

export const testsSelector = createDraftSafeSelector(
  stateSelector,
  prop<RootState['tests']>('tests'),
);

export const hasTestsUpdatingSelector = createDraftSafeSelector(
  testsSelector,
  ({ testsUpdating }) => testsUpdating.length > 0,
);

export const testDataSelector = createDraftSafeSelector(
  testsSelector,
  prop<RootState['tests']['entities']>('entities'),
);

export const countTestsSelector = createDraftSafeSelector(testsSelector, prop<number>('count'));
export const validationErrorsTestsSelector = createDraftSafeSelector(
  testsSelector,
  prop<TestValidationError[]>('validationErrors'),
);

export const employeesFilterSelector = createDraftSafeSelector(
  testsSelector,
  pipe(
    prop<RootState['tests']['filters']>('filters'),
    prop<FilterItemResponsePrivate[]>('employeesFilter'),
  ),
);

export const projectsFilterSelector = createDraftSafeSelector(
  testsSelector,
  pipe(
    prop<RootState['tests']['filters']>('filters'),
    prop<FilterItemResponsePrivate[]>('projectsFilter'),
  ),
);
