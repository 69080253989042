import { PermissionAction, PermissionResource } from '@tests/types';
import { inc } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePermissions } from '@/hooks';
import { projectsEntitySelector } from '@/selectors';
import { useGetProjectsListQuery } from '@/services';

import { SelectRender } from '../Select';

type FiltersProps = {
  allowedIds?: number[];
  onChange?: (value: number) => void;
  value?: number;
};

export const StatsProjectsFilter: React.FC<FiltersProps> = ({ allowedIds, onChange, value }) => {
  const [page, setPage] = useState(0);
  const [inited, setInited] = useState(false);
  const { data: projectsData, isFetching } = useGetProjectsListQuery(
    {
      offset: page * 30,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Project, PermissionAction.Read),
    },
  );

  const projects = useSelector(projectsEntitySelector.selectAll)?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const allowedProjects = useMemo(() => {
    const allowedIdsSet = new Set(allowedIds);

    return projects.filter(({ value: projectId }) => allowedIdsSet.has(projectId));
  }, [projects, allowedIds]);

  useEffect(() => {
    if (allowedProjects.length > 0 && !inited) {
      onChange(allowedProjects[0].value);
      setInited(true);
    }
  }, [allowedProjects]);

  const hasMore = projectsData && projectsData?.count > projects?.length;

  const fetchData = async () => {
    setPage(inc);
  };

  return (
    <SelectRender
      value={value}
      isModal
      options={projects}
      onChange={onChange}
      hasMore={hasMore}
      fetchData={fetchData}
      loading={isFetching}
    />
  );
};
