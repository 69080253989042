import { EditOutlined } from '@ant-design/icons';
import { RoleResponsePrivate } from '@tests/types';
import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';

import { getTextColor, getTextStatus } from '@/utils';

import { Button } from '../Button';
import styles from './styles.module.scss';

interface Props {
  isMobile: boolean;
  onEditClick: (role: RoleResponsePrivate<string>) => void;
  role: RoleResponsePrivate<string>;
  status?: string;
}
export const RoleRow: React.FC<Props> = ({ isMobile, onEditClick, role, status = 'active' }) => {
  const handleEditClick = () => {
    onEditClick(role);
  };

  if (isMobile) {
    return (
      <div className={styles.contentRow}>
        <Row justify="space-between" className={styles.role}>
          <Col>
            <Typography.Text>Роль:</Typography.Text>
          </Col>
          <Col>
            <Typography.Text className={styles[getTextColor(role?.name)]}>
              {role?.name}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button className={classNames(styles.button)} onClick={handleEditClick}>
              Редактировать
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Row align="middle" justify="space-between" className={styles.contentRow}>
      <Col span={4}>
        <Typography.Text className={styles[getTextColor(role?.name)]}>{role?.name}</Typography.Text>
      </Col>
      <Col span={17}>
        <Typography.Text className={styles[getTextColor(status)]}>
          {getTextStatus(status)}
        </Typography.Text>
      </Col>
      <Col span={1}>
        <Button className={classNames(styles.button, styles.editBtn)} onClick={handleEditClick}>
          <EditOutlined />
        </Button>
      </Col>
    </Row>
  );
};
