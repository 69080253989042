import { Col, Row, Typography } from 'antd';

import { CreateDropdown, TestsListHeader } from '@/containers';

import styles from './styles.module.scss';

export const NoAccessPage = () => (
  <>
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <TestsListHeader button={<CreateDropdown />} />
      </Col>
    </Row>
    <Row className={styles.wrapper} justify="center" align="middle">
      <Col>
        <Typography.Title level={2}>Доступ запрещен</Typography.Title>
        <Typography.Title level={3}>Обратитесь к администратору</Typography.Title>
      </Col>
    </Row>
  </>
);
