import { FileStatus } from '@tests/types';
import { always, cond, equals, lensIndex, path, pipe, prop, propEq, view } from 'ramda';
import { Accept } from 'react-dropzone';

import { DropType, MEGABYTE } from '@/constants';

export const getAccept = cond<[DropType], Accept>([
  [equals(DropType.Image), always({ 'image/jpeg': [], 'image/png': [] })],
  [equals(DropType.Gif), always({ 'image/gif': [] })],
  [equals(DropType.Video), always({ 'video/mp4': [] })],
  [
    equals(DropType.All),
    always({
      'image/gif': [],
      'image/jpeg': [],
      'image/png': [],
      // раскомментить для видео
      'video/mp4': [],
    }),
  ],
]);

export const getAcceptString = cond<[DropType], string>([
  [equals(DropType.Image), always('image/*')],
  [equals(DropType.Gif), always('image/*')],
  [equals(DropType.Video), always('video/*')],
  [equals(DropType.All), always('*/*')],
]);
export const getAcceptStringPlaceholder = cond<[DropType], string>([
  [equals(DropType.Image), always('jpg, png, jpeg')],
  [equals(DropType.Gif), always('gif')],
  [equals(DropType.Video), always('mp4')],
  [equals(DropType.All), always('jpg, png, jpeg, gif, mp4')],
]);

export const getMaxSize = cond<[DropType], number>([
  [equals(DropType.Image), always(MEGABYTE)],
  [equals(DropType.Gif), always(MEGABYTE)],
  [equals(DropType.Video), always(MEGABYTE * 10)],
  [equals(DropType.All), always(MEGABYTE * 2)],
]);

export const isImage = equals(DropType.Image);

export const isError = propEq(FileStatus.Error, 'status');

export const isComplete = propEq(FileStatus.Complete, 'status');

export const isInProgress = propEq(FileStatus.InProgress, 'status');

export const isConverting = propEq(FileStatus.Converting, 'status');

export const getErrorMessage = pipe(prop('errors'), view(lensIndex(0)), prop('message'));

export const getFileName = path<string>(['file', 'name']);
