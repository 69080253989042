import { EmployeeResponsePrivate, PermissionAction, PermissionResource } from '@tests/types';
import { Col, Row, Spin, Typography } from 'antd';
import { omit } from 'ramda';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UserRow } from '@/atoms';
import { InfiniteScroll } from '@/components';
import { useFilterPage, usePermissions } from '@/hooks';
import { isDefined } from '@/utils';

import { employeesEntitySelector } from '../../selectors';
import { useGetEmployeesListQuery } from '../../services';
import styles from './styles.module.scss';

interface Props {
  handleShowEditor: (item: EmployeeResponsePrivate<string>) => void;
  handleShowPasswordEditor: (item: EmployeeResponsePrivate<string>) => void;
  isMobile: boolean;
}

const UserFieldsRow = () => (
  <Row align="middle" justify="space-between" className={styles.fieldsRow}>
    <Col span={7}>
      <Typography.Text type="secondary">ФИО</Typography.Text>
    </Col>
    <Col span={4}>
      <Typography.Text type="secondary">ID</Typography.Text>
    </Col>
    <Col span={4}>
      <Typography.Text type="secondary">Статус</Typography.Text>
    </Col>
    <Col span={4}>
      <Typography.Text type="secondary">Проекты</Typography.Text>
    </Col>
    <Col span={1} />
  </Row>
);

export const EmployeeLists: React.FC<Props> = ({
  handleShowEditor,
  handleShowPasswordEditor,
  isMobile,
}) => {
  const { filter, setFilter } = useFilterPage();
  const { page = 1 } = useMemo(() => filter, [filter]);

  const fetchData = async () => {
    await setFilter({ offset: page * 30, page: Number(page) + 1 });
  };

  const { data, isFetching } = useGetEmployeesListQuery(
    {
      ...omit(['page'], filter),
      order: '+name',
      relations: ['role', 'avatar', 'projects.logo'],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Employee, PermissionAction.Read),
    },
  );

  const employees = useSelector(employeesEntitySelector.selectAll) || [];

  if (page === 0 && isFetching) {
    return (
      <Spin
        size="large"
        style={{
          padding: '10px',
          width: '100%',
        }}
      />
    );
  }
  if (data?.results?.length === 0) {
    return (
      <Typography.Text className={styles.stub}>
        По вашему запросу ничего не найдено.
      </Typography.Text>
    );
  }

  return (
    <InfiniteScroll
      hasMore={isDefined(data) && data?.count > employees?.length}
      isFetching={isFetching}
      fetchData={fetchData}
    >
      <div className={styles.rowsContainer}>
        <UserFieldsRow />
        {employees?.map((user: EmployeeResponsePrivate<string>) => (
          <UserRow
            user={user}
            onEditClick={handleShowEditor}
            onPasswordEditClick={handleShowPasswordEditor}
            isMobile={isMobile}
          />
        ))}
      </div>
    </InfiniteScroll>
  );
};
