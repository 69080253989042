import { EditOutlined, LockOutlined } from '@ant-design/icons';
import { EmployeeResponsePrivate, PermissionAction, PermissionResource } from '@tests/types';
import { Avatar, Badge, Col, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { TestoLogo, User } from '@/assets';
import { usePermissions } from '@/hooks';
import { userRoleSelector } from '@/selectors';
import { useGetCurrentUserQuery } from '@/services';
import { getTextColor, getTextStatus } from '@/utils';

import { Button } from '../Button';
import styles from './styles.module.scss';

interface Props {
  isMobile: boolean;
  onEditClick: (user: EmployeeResponsePrivate<string>) => void;
  onPasswordEditClick: (user: EmployeeResponsePrivate<string>) => void;
  user: EmployeeResponsePrivate<string>;
}

export const UserRow: React.FC<Props> = ({ isMobile, onEditClick, onPasswordEditClick, user }) => {
  const { data: currentUser } = useGetCurrentUserQuery();

  const isSuper = useSelector(userRoleSelector)?.isSuper;

  const isMe = user?.id === currentUser?.id;

  const checkPermissions = usePermissions;

  const canChangePassword =
    (checkPermissions(PermissionResource.Employee, PermissionAction.Create) &&
      currentUser?.id === user?.id) ||
    isSuper;

  const handleEditClick = () => {
    onEditClick(user);
  };

  const handleEditPasswordClick = () => {
    onPasswordEditClick(user);
  };

  if (isMobile) {
    return (
      <Row align="middle" className={styles.contentRow}>
        <Col span={24}>
          <Row gutter={[8, 8]} className={styles.name}>
            <Col>
              <Avatar
                src={user?.avatar?.url}
                className={classNames(styles.avatarWrapper, !user?.avatar && styles.avatar)}
              >
                {!user?.avatar && <User width={24} height={24} />}
              </Avatar>
            </Col>
            <Col>
              <Typography.Text>{user.name}</Typography.Text>
              <Typography.Text type="secondary">{isMe && ' (Вы)'}</Typography.Text>
              <br />
              <Typography.Text type="secondary" className={styles[getTextColor(user?.role?.name)]}>
                {user?.role?.name}
              </Typography.Text>
            </Col>
          </Row>
          <Row justify="space-between" className={styles.describe}>
            <Col>
              <Typography.Text type="secondary">ID:</Typography.Text>
              <br />
              <Typography.Text type="secondary">Статус:</Typography.Text>
            </Col>
            <Col className={styles.describeValues}>
              <Typography.Text>{user?.id}</Typography.Text>
              <br />
              <Typography.Text type="secondary" className={styles[getTextColor(user?.status)]}>
                {getTextStatus(user?.status)}
              </Typography.Text>
            </Col>
          </Row>
          <Row className={styles.projectsWrapper}>
            <div className={styles.projects}>
              {user?.projects?.map((project) => (
                <Badge className="project">
                  {project?.logo?.url ? (
                    <img height={12} src={project?.logo?.url} alt={project.name} />
                  ) : (
                    <TestoLogo style={{ height: '12px' }} />
                  )}
                </Badge>
              ))}
            </div>
          </Row>
          <Row gutter={[5, 5]}>
            <Col flex="auto">
              <Button
                className={classNames(styles.button)}
                disabled={!checkPermissions(PermissionResource.Employee, PermissionAction.Update)}
                onClick={handleEditClick}
              >
                Редактировать
              </Button>
            </Col>
            <Col>
              <Button
                className={classNames(styles.button)}
                disabled={!canChangePassword}
                onClick={handleEditPasswordClick}
              >
                <LockOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <Row align="middle" justify="space-between" className={styles.contentRow}>
      <Col span={7}>
        <Row gutter={[8, 8]}>
          <Col>
            <Avatar
              src={user?.avatar?.url}
              className={classNames(styles.avatarWrapper, !user?.avatar && styles.avatar)}
            >
              {!user?.avatar && <User width={24} height={24} />}
            </Avatar>
          </Col>
          <Col>
            <Typography.Text>{user.name}</Typography.Text>
            <Typography.Text type="secondary">{isMe && ' (Вы)'}</Typography.Text>
            <br />
            <Typography.Text type="secondary" className={styles[getTextColor(user?.role?.name)]}>
              {user?.role?.name}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <Typography.Text>{user?.id}</Typography.Text>
      </Col>
      <Col span={3}>
        <Typography.Text type="secondary" className={styles[getTextColor(user?.status)]}>
          {getTextStatus(user?.status)}
        </Typography.Text>
      </Col>
      <Col span={4}>
        <Space size={10} wrap>
          {user?.projects?.map((project) => (
            <Badge className="project">
              {project?.logo?.url ? (
                <img height={12} src={project?.logo?.url} alt={project.name} />
              ) : (
                <TestoLogo style={{ height: '12px' }} />
              )}
            </Badge>
          ))}
        </Space>
      </Col>
      <Col span={2}>
        <Row gutter={[5, 5]}>
          <Col>
            <Button
              className={classNames(styles.button, styles.editBtn)}
              disabled={!checkPermissions(PermissionResource.Employee, PermissionAction.Update)}
              onClick={handleEditClick}
            >
              <EditOutlined />
            </Button>
          </Col>
          <Col>
            <Button
              className={classNames(styles.button)}
              disabled={!canChangePassword}
              onClick={handleEditPasswordClick}
            >
              <LockOutlined />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
