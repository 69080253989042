import { createSlice, isRejected } from '@reduxjs/toolkit';
import { Http } from '@status/codes';
import type { Employee } from '@tests/types';
import { has } from 'ramda';

import { usersApi } from '@/services';

interface State {
  isAuthenticated: boolean;
  token: string;
  user: Employee;
}

export const userSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(usersApi.endpoints.oAuthCallback.matchFulfilled, (state, action) => {
        state.token = action.payload.accessToken;
        state.isAuthenticated = true;
        localStorage.setItem('token', state.token);
      })
      .addMatcher(usersApi.endpoints.loginUser.matchFulfilled, (state, action) => {
        state.token = action.payload.accessToken;
        state.isAuthenticated = true;
        localStorage.setItem('token', state.token);
      })
      .addMatcher(usersApi.endpoints.oAuthCallback.matchRejected, (state) => {
        state.isAuthenticated = false;
        localStorage.removeItem('token');
      })
      .addMatcher(usersApi.endpoints.logoutUser.matchFulfilled, (state) => {
        state.isAuthenticated = false;
        state.user = null;
        localStorage.removeItem('token');
      })
      .addMatcher(usersApi.endpoints.getCurrentUser.matchFulfilled, (state, { payload: user }) => {
        state.user = user;
      })
      .addDefaultCase((state, action) => {
        if (isRejected(action)) {
          if (has('status', action.payload) && action.payload.status === Http.Unauthorized) {
            state.user = null;
            state.isAuthenticated = false;
            localStorage.removeItem('token');
          }
        }
      });
  },
  initialState: {
    expiry: null,
    isAuthenticated: !!localStorage.getItem('token'),
    token: localStorage.getItem('token'),
    user: null,
  } as State,
  name: 'user',
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const userReducer = userSlice.reducer;
